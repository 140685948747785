import { getAuth, signOut } from 'firebase/auth';

// eslint-disable-next-line import/prefer-default-export
export const onFinish = async (returnTo, refreshToken = null) => {
    const env = process.env.REACT_APP_ENV || 'DEV';
    await signOut(getAuth());

    if (returnTo) {
        window.location.href = `${returnTo}?refreshToken=${refreshToken}`;
        return;
    }

    const domain = env === 'PROD' ? 'https://subscription-avondale-prod.web.app/subscription' : 'https://subscription-avondale-dev.web.app/subscription';
    window.location.href = `${domain}`;
};
